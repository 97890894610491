'use client';

import {PropsWithChildren, useMemo, useEffect} from 'react';
import {RelayEnvironmentProvider} from 'react-relay';
import {RecordSource} from 'relay-runtime';

import {initRelayEnvironment} from '@/relay-environment';

import type {RecordMap} from 'relay-runtime/lib/store/RelayStoreTypes';

import type {MockEnvironment} from 'relay-test-utils';
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';

export function AtlasRelayEnvProvider({
  children,
  initialRecords,
  relayEnvironment,
  sequenceId,
}: PropsWithChildren & {
  initialRecords?: RecordMap;
  sequenceId?: string;
  relayEnvironment?: MockEnvironment | RelayModernEnvironment;
}) {
  // on the server, this is always a fresh env, which is fine for our purposes
  // on the client, it is memoized
  // we should be intentional about when we need to add another relay env provider for server rendering
  const environment = useMemo(
    () => relayEnvironment ?? initRelayEnvironment({sequenceId, initialRecords}),
    [relayEnvironment, sequenceId, initialRecords],
  );

  useEffect(() => {
    const store = environment.getStore();

    // Hydrate the store.
    store.publish(new RecordSource(initialRecords));

    // Notify any existing subscribers.
    store.notify();
  }, [environment, initialRecords]);

  return <RelayEnvironmentProvider environment={environment}>{children}</RelayEnvironmentProvider>;
}
