import {Pill as MantinePill, useComputedColorScheme, darken, lighten} from '@mantine/core';
import classes from '@/ui/theme/themed-components/pill.module.css';
import {CSSProperties} from 'react';

interface PillCss extends CSSProperties {
  '--pill-bg'?: string;
  '--pill-remove-button-color'?: string;
}

export const Pill = MantinePill.extend({
  defaultProps: {
    radius: 'sm',
    variant: 'light',
    size: 'sm',
  },
  classNames: {
    root: classes.root,
    remove: classes.remove,
  },
  styles: (theme, props) => {
    const currentScheme: 'light' | 'dark' = useComputedColorScheme('light'); // eslint-disable-line react-hooks/rules-of-hooks
    const root: PillCss = {};
    if (props.variant === 'light') {
      if (currentScheme === 'dark') {
        root['--pill-bg'] = theme.colors.dark[4];
        if (props.color && theme.colors[props.color]) {
          root['--pill-bg'] = darken(theme.colors[props.color][3], 0.75);
          root['--pill-remove-button-color'] = theme.colors[props.color][5];
        }
      } else {
        root['--pill-bg'] = theme.colors.default[1];
        if (props.color && theme.colors[props.color]) {
          root['--pill-bg'] = lighten(theme.colors[props.color][1], 0.1);
          root['--pill-remove-button-color'] = theme.colors[props.color][6];
        }
      }
    }
    return {
      root,
    };
  },
});
