import {MantineColorsTuple} from '@mantine/core';
import colors from 'open-color';

export type ThemeColor =
  | 'primary'
  | 'danger'
  | 'success'
  | 'default'
  | 'dark'
  | 'gray'
  | 'red'
  | 'pink'
  | 'grape'
  | 'violet'
  | 'indigo'
  | 'blue'
  | 'cyan'
  | 'green'
  | 'lime'
  | 'yellow'
  | 'orange'
  | 'teal';

export const primary: MantineColorsTuple = [
  '#fbfaff',
  '#dcd0ff',
  '#bda6ff',
  '#9d7bff',
  '#7b4eff',
  '#5200ff',
  '#4D08DF',
  '#3901B4',
  '#27007D',
  '#13003A',
  // '#3f07ca',
  // '#2e0997',
  // '#1f0767',
  // '#13003a',
];

export const gray: MantineColorsTuple = [
  '#f9fafb',
  '#e5e8eb',
  '#d2d7dc',
  '#c0c5cd',
  '#aeb4be',
  '#9ca3af',
  '#7A828F',
  '#5A6370',
  '#3C4553',
  '#1F2937',
];

export const dark: MantineColorsTuple = [
  '#BDC2CD',
  '#A2A7B3',
  '#8C929D',
  '#595F6C',
  '#343A45',
  '#282E39',
  '#222630',
  '#171B23',
  '#11151C',
  '#0D1117',
];

export const danger: MantineColorsTuple = [
  colors.red[0],
  colors.red[1],
  colors.red[2],
  colors.red[3],
  colors.red[4],
  colors.red[5],
  colors.red[6],
  colors.red[7],
  colors.red[8],
  colors.red[9],
];

export const success: MantineColorsTuple = [
  colors.green[0],
  colors.green[1],
  colors.green[2],
  colors.green[3],
  colors.green[4],
  colors.green[5],
  colors.green[6],
  colors.green[7],
  colors.green[8],
  colors.green[9],
];

export function colorObject(name: string, scale: MantineColorsTuple) {
  const obj: {[key: string]: string} = {};
  scale.forEach((value, index) => {
    obj[`--mantine-color-${name}-${index}`] = value;
  });
  return obj;
}
