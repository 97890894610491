import {MenuItem as MantineMenuItem} from '@mantine/core';
import classes from '@/ui/theme/themed-components/menu-item.module.css';

export const MenuItem = MantineMenuItem.extend({
  defaultProps: {
    classNames: {
      item: classes.root,
      itemLabel: classes.label,
      itemSection: classes.section,
    },
  },
});
