import {Input as MantineInput} from '@mantine/core';
import {heights, fontSizes} from '@/ui/theme/sizes';
import classes from '@/ui/theme/themed-components/input.module.css';

export const Input = MantineInput.extend({
  vars: (theme, props) => {
    if (typeof props.size === 'string' && heights[props.size] && fontSizes[props.size]) {
      return {
        wrapper: {
          '--input-height': heights[props.size],
          '--input-fz': fontSizes[props.size],
        },
      };
    }
    return {
      wrapper: {},
    };
  },
  classNames: {
    input: classes.input,
    section: classes.section,
  },
});
