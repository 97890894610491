import {Breadcrumbs as MantineBreadcrumbs} from '@mantine/core';
import {SvgChevronRight} from '@/ui/icon/chevron-right';

export const Breadcrumbs = MantineBreadcrumbs.extend({
  defaultProps: {
    separator: <SvgChevronRight size={16} />,
  },
  styles: {
    breadcrumb: {
      fontSize: 'var(--mantine-font-size-sm)',
      fontWeight: 'var(--mantine-font-weight-medium)',
      color: 'var(--mantine-color-dimmed)',
    },
    separator: {
      fontSize: 'var(--mantine-font-size-md)',
      fontWeight: 'var(--mantine-font-weight-medium)',
      color: 'var(--mantine-color-dimmed)',
    },
  },
});
