import {MantineTheme, lighten, darken} from '@mantine/core';

export function cssVariablesResolver(theme: MantineTheme) {
  return {
    variables: {
      '--mantine-icon-size-xs': theme.other.iconSizes['xs'],
      '--mantine-icon-size-sm': theme.other.iconSizes['sm'],
      '--mantine-icon-size-md': theme.other.iconSizes['md'],
      '--mantine-icon-size-lg': theme.other.iconSizes['lg'],
      '--mantine-icon-size-xl': theme.other.iconSizes['xl'],
      '--mantine-icon-size-compact-xs': theme.other.iconSizes['compact-xs'],
      '--mantine-icon-size-compact-sm': theme.other.iconSizes['compact-sm'],
      '--mantine-icon-size-compact-md': theme.other.iconSizes['compact-md'],
      '--mantine-icon-size-compact-lg': theme.other.iconSizes['compact-lg'],
      '--mantine-icon-size-compact-xl': theme.other.iconSizes['compact-xl'],
      '--mantine-height-xs': theme.other.heights['xs'],
      '--mantine-height-sm': theme.other.heights['sm'],
      '--mantine-height-md': theme.other.heights['md'],
      '--mantine-height-lg': theme.other.heights['lg'],
      '--mantine-height-xl': theme.other.heights['xl'],
      '--mantine-color-gray-1-lightened': lighten(theme.colors.gray[1], 0.4),
      '--mantine-font-weight-light': '400',
      '--mantine-font-weight-medium': '500',
      '--mantine-font-weight-semi-bold': '600',
      '--mantine-font-weight-bold': '700',
    },
    dark: {
      '--mantine-color-text': theme.colors.gray[3],
      '--mantine-color-contrast': 'var(--mantine-color-white)',
      '--mantine-color-body': theme.colors.dark[8],
      '--mantine-color-body-primary': darken(theme.colors.primary[5], 0.9),
      '--mantine-color-hover': theme.colors.dark[5],
      '--mantine-color-selected': theme.colors.dark[4],
      '--mantine-color-container': theme.colors.dark[7],
      '--mantine-color-overlay': theme.colors.dark[6],
      '--mantine-color-border': theme.colors.dark[4],
      '--mantine-color-border-subtle': theme.colors.dark[5],
    },
    light: {
      '--mantine-color-text': theme.colors.dark[4],
      '--mantine-color-contrast': 'var(--mantine-color-black)',
      '--mantine-color-body': theme.colors.gray[0],
      '--mantine-color-body-primary': theme.colors.primary[0],
      '--mantine-color-hover': 'var(--mantine-color-gray-1-lightened)',
      '--mantine-color-selected': theme.colors.gray[1],
      '--mantine-color-container': 'var(--mantine-color-white)',
      '--mantine-color-overlay': 'var(--mantine-color-white)',
      '--mantine-color-border': theme.colors.gray[2],
      '--mantine-color-border-subtle': theme.colors.gray[1],
    },
  };
}
