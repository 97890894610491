import React, {ElementType} from 'react';
import {Tooltip as MantineTooltip, TooltipProps} from '@mantine/core';
import classes from '@/ui/theme/themed-components/tooltip.module.css';

export const Tooltip = MantineTooltip.extend({
  defaultProps: {
    radius: 'sm',
    openDelay: 300,
    multiline: true,
    maw: 200,
    transitionProps: {transition: 'fade', duration: 200},
    classNames: {
      tooltip: classes.tooltip,
    },
  },
});

interface TooltipHOCprops extends Omit<TooltipProps, 'label'> {
  // Wrap children in an element (eg. truncation)
  Wrapper?: ElementType;
  // Conditionally apply the tooltip
  isEnabled?: boolean;
}

/**
 * Use when you want a tooltip with equivalent content to the tooltip trigger
 *
 * Commonly, you'll provide a Wrapper that truncates the text
 */
export function WrapWithTooltip({
  children,
  isEnabled = true,
  Wrapper = 'div',
  ...props
}: TooltipHOCprops) {
  if (!isEnabled) return <Wrapper>{children}</Wrapper>;

  return (
    <MantineTooltip label={children} {...props}>
      <Wrapper>{children}</Wrapper>
    </MantineTooltip>
  );
}
