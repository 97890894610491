import {CSSProperties} from 'react';
import {Divider as MantineDivider} from '@mantine/core';

export const Divider = MantineDivider.extend({
  styles: {
    root: {
      '--divider-color': 'var(--mantine-color-border-subtle)',
    } as CSSProperties,
    label: {
      color: 'var(--mantine-color-dimmed)',
      fontWeight: 'var(--mantine-font-weight-semi-bold)',
    },
  },
});
