import {AppShell as MantineAppShell} from '@mantine/core';
import classes from '@/ui/theme/themed-components/app-shell.module.css';

export const AppShell = MantineAppShell.extend({
  classNames: {
    root: classes.root,
    navbar: classes.container,
    header: classes.container,
    main: classes.main,
    aside: classes.container,
  },
});
