import {Check} from '@phosphor-icons/react';
import {Checkbox as MantineCheckbox, CheckboxProps} from '@mantine/core';

// allowing arrow fn for easier typing here
// eslint-disable-next-line
const CheckboxIcon: CheckboxProps['icon'] = (props) => {
  // TODO: render a different default icon when indeterminate
  // but as of this change, we do not currently support any intedetminate states
  const {indeterminate: _omit, ...others} = props;
  return <Check {...others} weight="bold" />;
};

export const Checkbox = MantineCheckbox.extend({
  defaultProps: {
    icon: CheckboxIcon,
  },
  styles: {
    icon: {width: '75%', height: '75%'},
  },
});
