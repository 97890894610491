import {
  ScrollArea as MantineScrollArea,
  ScrollAreaAutosize as MantineScrollAreaAutosize,
} from '@mantine/core';

export const ScrollArea = MantineScrollArea.extend({
  defaultProps: {
    type: 'scroll',
  },
});

export const ScrollAreaAutosize = MantineScrollAreaAutosize.extend({
  defaultProps: {
    type: 'scroll',
  },
});
