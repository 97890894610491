import {useParams} from 'next/navigation';
import {error} from '@/logger/client-logger';
import {AUTH_SSO_URL, FE_BASE_ENDPOINT} from '@/config';
import {slugFromRelayId} from '@/utils/network-parser';
import {Path} from './path-constants';

type BaseLocaleArgs = {
  locale?: string;
};

export type BaseRootNetworkArgs = BaseLocaleArgs & {
  slug?: string;
};

type NetworkItemArgs = BaseRootNetworkArgs & {id: string};

export type GetPathParameters =
  | [
      path: typeof AUTH_SSO_URL,
      parameters?: {searchParams?: {state?: `${'network_invitation' | 'redirect'}|${string}`}},
    ]
  | [path: Path.SignIn, parameters?: BaseLocaleArgs & {searchParams?: {magicLink?: string}}]
  | [path: Path.SignUp, parameters?: BaseLocaleArgs & {searchParams?: {magicLink?: string}}]
  | [path: Path.Lists]
  | [path: Path.Home]
  | [path: Path.ResetPassword]
  | [path: Path.ConfirmDetails, parameters?: BaseLocaleArgs & {searchParams?: {magicLink?: string}}]
  | [path: Path.TeamSetup, parameters?: BaseLocaleArgs & {searchParams?: {magicLink?: string}}]
  | [path: Path.OnboardingInvite, parameters: BaseRootNetworkArgs]
  | [
      path: Path.OnboardingSyncNetwork,
      parameters: BaseRootNetworkArgs & {searchParams?: {magicLink?: string}},
    ]
  | [path: Path.NetworkHome, parameters?: BaseRootNetworkArgs]
  | [path: Path.People, parameters?: BaseRootNetworkArgs]
  | [path: Path.Sources, parameters?: BaseRootNetworkArgs]
  | [path: Path.Folder, parameters: NetworkItemArgs]
  | [path: Path.Space, parameters: NetworkItemArgs]
  | [path: Path.PersonListDefault, parameters: NetworkItemArgs]
  | [path: Path.PersonListTable, parameters: NetworkItemArgs]
  | [path: Path.PersonListBoardDefault, parameters: NetworkItemArgs]
  | [path: Path.PersonListBoard, parameters: NetworkItemArgs & {personalizedFieldId: string}]
  | [path: Path.SettingsMyDetails, parameters?: BaseRootNetworkArgs]
  | [path: Path.SettingsNetworkDetails, parameters?: BaseRootNetworkArgs]
  | [path: Path.SettingsNetworkSyncing, parameters?: BaseRootNetworkArgs]
  | [path: Path.SettingsNetworkMembers, parameters?: BaseRootNetworkArgs]
  | [path: Path.SettingsNetworkImports, parameters?: BaseRootNetworkArgs]
  | [path: Path.PublicPersonTable, parameters: BaseLocaleArgs & {publicIdentifier: string}]
  | [path: Path.Activity, parameters?: BaseRootNetworkArgs]
  | [
      path: Path.SignUpWithEmail,
      parameters: BaseLocaleArgs & {searchParams: {email: string; magicLink?: string}},
    ]
  | [
      path: Path.VerifyEmail,
      parameters: BaseLocaleArgs & {
        searchParams: {email: string; token?: string; magicLink?: string};
      },
    ]
  | [
      path: Path.MagicLinkLandingPage,
      parameters: BaseLocaleArgs & {searchParams: {magicLink: string}},
    ]
  | [path: Path.OnboardingSyncNetwork, parameters: BaseRootNetworkArgs]
  | [path: Path.OnboardingInvite, parameters: BaseRootNetworkArgs]
  | [path: Path.AiSearch, parameters?: BaseRootNetworkArgs];

export type GetPersonListDefaultPathParameters = {
  id: string;
  defaultView?:
    | {
        __typename:
          | 'PersonListNetworkItem'
          | 'CustomField'
          | 'PersonalizedFieldSingleSelectStringOption';
        id: string;
      }
    | {__typename: '%other'}
    | null;
  rootSlug?: string;
};

export function useGetPath(
  /** whether the resolved path will be an absolute URL containing scheme and domains*/
  fullyQualified = false,
) {
  const {locale, slug} = useParams<{locale: string; slug: string}>();

  const baseParameters: Record<string, string> = {
    locale,
  };

  if (slug) {
    baseParameters.slug = slug;
  }

  function getPath(...args: GetPathParameters): string {
    const allParameters = {
      ...baseParameters,
      ...args[1],
    };

    // "satisifes" operator to require searchParams to be an object in all union members
    const searchParams = (
      args[1] && 'searchParams' in args[1] && typeof args[1].searchParams !== 'undefined'
        ? args[1].searchParams
        : {}
    ) satisfies Record<string, string | undefined>;

    const searchParamEntries = Object.entries(searchParams).filter(([, v]) => Boolean(v));

    const searchQuery =
      searchParamEntries.length > 0 ? `?${new URLSearchParams(searchParamEntries)}` : '';

    let finalPath: string = args[0];

    Object.entries(allParameters)
      .filter(([key]) => key !== 'searchParams')
      .forEach(([key, value]) => {
        finalPath = finalPath.replace(`{${key}}`, value as string);
      });

    return `${fullyQualified ? FE_BASE_ENDPOINT : ''}${finalPath}${searchQuery}`;
  }

  function getPersonListDefaultPath({
    id,
    defaultView,
    rootSlug,
  }: GetPersonListDefaultPathParameters) {
    const listSlug = slugFromRelayId(id);

    let options: {
      id: string;
      personalizedFieldId?: string;
      slug?: string;
    } = {id: listSlug};

    if (rootSlug) {
      options = {...options, slug: rootSlug};
    }

    if (defaultView?.__typename === 'PersonListNetworkItem') {
      return getPath(Path.PersonListTable, options);
    }

    if (
      defaultView?.__typename === 'CustomField' ||
      defaultView?.__typename === 'PersonalizedFieldSingleSelectStringOption'
    ) {
      return getPath(Path.PersonListBoard, {...options, personalizedFieldId: defaultView.id});
    }

    // We couldn't figure it out. Hit the naked route and redirect.
    return getPath(Path.PersonListDefault, options);
  }

  function getNetworkItemPath({id, type}: {id: string; type: string}) {
    const slug = slugFromRelayId(id);

    if (type === 'PersonListNetworkItem') {
      return getPath(Path.PersonListTable, {id: slug});
    }
    if (type === 'FolderNetworkItem') {
      return getPath(Path.Folder, {id: slug});
    }
    if (type === 'NetworkSpace') {
      return getPath(Path.Space, {id: slug});
    }

    error(new Error('getNetworkItemPath called with unexpected network type'), undefined, {
      type,
      id,
    });

    return getPath(Path.NetworkHome);
  }

  return {
    getPath,
    getNetworkItemPath,
    getPersonListDefaultPath,
  };
}
