import {rem} from '@mantine/core';

export interface sizeList {
  [key: string]: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  'compact-xs': string;
  'compact-sm': string;
  'compact-md': string;
  'compact-lg': string;
  'compact-xl': string;
}

export const heights: sizeList = {
  xxs: rem('24px'),
  xs: rem('26px'),
  sm: rem('32px'),
  md: rem('36px'),
  lg: rem('42px'),
  xl: rem('50px'),
  'compact-xxs': rem('20px'),
  'compact-xs': rem('22px'),
  'compact-sm': rem('26px'),
  'compact-md': rem('30px'),
  'compact-lg': rem('34px'),
  'compact-xl': rem('40px'),
};

export const fontSizes: sizeList = {
  xs: rem('11px'),
  sm: rem('12px'),
  md: rem('13px'),
  lg: rem('14px'),
  xl: rem('15px'),
  'compact-xs': rem('11px'),
  'compact-sm': rem('12px'),
  'compact-md': rem('13px'),
  'compact-lg': rem('14px'),
  'compact-xl': rem('15px'),
};

export const iconSizes: sizeList = {
  xs: rem(22),
  sm: rem(24),
  md: rem(26),
  lg: rem(26),
  xl: rem(28),
  'compact-xs': `calc(1.6 * ${fontSizes['compact-xs']})`,
  'compact-sm': `calc(1.6 * ${fontSizes['compact-sm']})`,
  'compact-md': `calc(1.5 * ${fontSizes['compact-md']})`,
  'compact-lg': `calc(1.5 * ${fontSizes['compact-lg']})`,
  'compact-xl': `calc(1.4 * ${fontSizes['compact-xl']})`,
};
