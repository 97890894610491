'use client';

import {VercelToolbar as ActualVercelToolbar} from '@vercel/toolbar/next';
import {usePrefab} from '@prefab-cloud/prefab-cloud-react';
import {ApplicationConfiguration} from '@/config';

import {useCurrentUser} from '@/providers/current-user-provider';

export function VercelToolbar() {
  const {currentUser} = useCurrentUser({allowRedirect: false});
  const {isEnabled} = usePrefab();

  if (!currentUser?.primaryEmail?.endsWith('@huntclub.com')) {
    return null;
  }

  if (!ApplicationConfiguration.configureVercelToolbar && !isEnabled('enable-vercel-toolbar')) {
    return null;
  }

  return <ActualVercelToolbar />;
}
