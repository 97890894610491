import {Button as MantineButton, rgba, rem} from '@mantine/core';
import {heights, fontSizes, sizeList} from '@/ui/theme/sizes';
import classes from '@/ui/theme/themed-components/button.module.css';

const DEFAULT_SIZE = 'md';
const DEFAULT_VARIANT = 'default';
const DEFAULT_COLOR = 'default';

interface VarsObject {
  root: {
    '--button-height'?: string;
    '--button-fz'?: string;
    '--button-padding-x'?: string;
    '--mantine-spacing-xs'?: string;
    '--button-icon-color'?: string;
    '--button-bd'?: string;
    '--button-bg'?: string;
    '--button-color'?: string;
    '--button-hover'?: string;
  };
}

export const buttonFontSizes: sizeList = {
  xs: fontSizes.xs,
  sm: fontSizes.sm,
  md: fontSizes.sm,
  lg: fontSizes.md,
  xl: fontSizes.lg,
  'compact-xs': fontSizes.xs,
  'compact-sm': fontSizes.sm,
  'compact-md': fontSizes.sm,
  'compact-lg': fontSizes.md,
  'compact-xl': fontSizes.lg,
};

export const Button = MantineButton.extend({
  defaultProps: {
    size: DEFAULT_SIZE,
    variant: DEFAULT_VARIANT,
    color: DEFAULT_COLOR,
  },
  vars: (theme, props) => {
    const shade = typeof theme.primaryShade === 'number' ? theme.primaryShade : 5;
    const currentColor = props.color
      ? theme.colors[props.color] ?? theme.colors.default
      : theme.colors.default;

    const vars: VarsObject = {
      root: {
        '--button-height':
          typeof props.size === 'string' && heights[props.size]
            ? heights[props.size]
            : heights[DEFAULT_SIZE],
        '--button-fz':
          typeof props.size === 'string' && buttonFontSizes[props.size]
            ? buttonFontSizes[props.size]
            : buttonFontSizes[DEFAULT_SIZE],
        '--button-padding-x':
          typeof props.size === 'string' && props.size.startsWith('compact-')
            ? 'calc(var(--button-height) * 0.25)'
            : 'calc(var(--button-height) * 0.5)',
        '--mantine-spacing-xs': '0.5em',
        '--button-icon-color': 'var(--button-color)',
      },
    };
    if (['default', 'subtle'].includes(props.variant || '') && props.color === 'default') {
      vars.root['--button-icon-color'] = 'var(--mantine-color-gray-6)';
    }

    if (props.variant === 'gradient') {
      vars.root['--button-bd'] = `${rem(1)} solid ${theme.colors.primary[7]}`;
    }

    if (props.disabled) {
      vars.root['--button-icon-color'] = 'var(--_disabled-color)';
      vars.root['--button-bd'] = `${rem(1)} solid transparent`;
    }

    if (props.variant === 'filled') {
      if (props.color === 'default') {
        vars.root['--button-bg'] =
          'light-dark(var(--mantine-color-primary-9), var(--mantine-color-primary-0))';
        vars.root['--button-color'] =
          'light-dark(var(--mantine-color-primary-0), var(--mantine-color-primary-9))';
        vars.root['--button-hover'] =
          'light-dark(var(--mantine-color-primary-9), var(--mantine-color-primary-1))';
      } else if (currentColor) {
        vars.root['--button-bg'] = currentColor[shade];
        vars.root['--button-color'] = currentColor[0];
        vars.root['--button-hover'] = currentColor[shade + 1];
      }
    }

    if (props.variant === 'default') {
      if (props.color === 'default') {
        vars.root['--button-hover'] = 'var(--mantine-color-default-hover)';
        vars.root['--button-color'] = 'var(--mantine-color-default-color)';
        vars.root['--button-bd'] = `${rem(1)} solid var(--mantine-color-default-border)`;
      } else if (currentColor) {
        vars.root['--button-hover'] = `light-dark(${rgba(currentColor[shade - 1], 0.1)}, ${rgba(
          currentColor[shade - 2],
          0.15,
        )})`;
        vars.root['--button-color'] = `light-dark(${currentColor[shade]}, ${
          currentColor[shade - 2]
        })`;
        vars.root['--button-bd'] = `light-dark(${rem(1)} solid ${currentColor[shade - 3]}, ${rem(
          1,
        )} solid ${currentColor[shade - 2]})`;
      }
    }

    if (props.variant === 'subtle') {
      if (props.color === 'default') {
        vars.root['--button-hover'] = rgba(theme.colors.gray[shade], 0.15);
        vars.root['--button-color'] = 'var(--mantine-color-default-color)';
      } else if (currentColor) {
        vars.root['--button-hover'] = `light-dark(${rgba(currentColor[shade - 1], 0.1)}, ${rgba(
          currentColor[shade],
          0.15,
        )})`;
        vars.root['--button-color'] = `light-dark(${currentColor[shade]}, ${
          currentColor[shade - 1]
        })`;
      }
    }

    return vars;
  },
  classNames: {
    root: classes.root,
  },
});
