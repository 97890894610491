type NetworkToRelayIdProps = {
  __typename: 'FolderNetworkItem' | 'PersonListNetworkItem' | 'NetworkSpace' | 'NetworkRoot';
  slug: string;
};

export function spaceToRelayId(slug: string) {
  return networkToRelayId({__typename: 'NetworkSpace', slug});
}

export function listToRelayId(slug: string) {
  return networkToRelayId({__typename: 'PersonListNetworkItem', slug});
}

export function folderToRelayId(slug: string) {
  return networkToRelayId({__typename: 'FolderNetworkItem', slug});
}

export function rootToRelayId(slug: string) {
  return networkToRelayId({__typename: 'NetworkRoot', slug});
}

export function networkToRelayId({__typename, slug}: NetworkToRelayIdProps): string {
  const json = JSON.stringify({v: 1, klass: 'Network', slug});

  return btoa(`${__typename}:${json}`);
}

export function slugFromRelayId(id: string): string {
  try {
    const rawId = atob(id);
    const jsonString = rawId.substring(rawId.indexOf('{'));
    const json = JSON.parse(jsonString);

    return json['slug'];
  } catch (e) {
    throw new Error(`An error occurred parsing the slug from relayId ${id}`, {cause: e});
  }
}
