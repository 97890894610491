import {Avatar as MantineAvatar} from '@mantine/core';
import classes from '@/ui/theme/themed-components/avatar.module.css';

export const Avatar = MantineAvatar.extend({
  defaultProps: {
    classNames: {
      root: classes.root,
      image: classes.image,
      placeholder: classes.placeholder,
    },
    radius: 'xl',
  },
});
