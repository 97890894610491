import {AppRouterInstance} from 'next/dist/shared/lib/app-router-context.shared-runtime';
import {createContext, useContext, PropsWithChildren} from 'react';

import {
  useGetPath,
  GetPathParameters,
  GetPersonListDefaultPathParameters,
} from '@/hooks/routing/use-get-path';

export type ContextValue = {
  push: (...args: GetPathParameters) => void;
  pushUnsafe: (path: string) => void;
  /** prefetch for faster transitions */
  prefetch: (...args: GetPathParameters) => void;
  goToPersonList: (args: GetPersonListDefaultPathParameters) => void;
  goToNetworkItem: (args: GetNetworkItemPathParameters) => void;
};

export const NavigationContext = createContext<ContextValue | null>(null);

interface GetNetworkItemPathParameters {
  id: string;
  type: string;
}

export function NavigationProvider({
  router,
  children,
}: PropsWithChildren<{router: AppRouterInstance}>) {
  const {getPath, getPersonListDefaultPath, getNetworkItemPath} = useGetPath();

  function prefetch(...args: GetPathParameters) {
    router.prefetch(getPath(...args));
  }

  function push(...args: GetPathParameters) {
    router.push(getPath(...args));
  }

  // Add regex for validation: https://linear.app/hunt-club/issue/ENG-1476/validate-server-paths
  /** Push method without Path validation. Escape hatch for redirects coming from the server */
  function pushUnsafe(path: string) {
    router.push(path);
  }

  function goToPersonList(args: GetPersonListDefaultPathParameters) {
    router.push(getPersonListDefaultPath(args));
  }
  function goToNetworkItem(args: GetNetworkItemPathParameters) {
    router.push(getNetworkItemPath(args));
  }

  return (
    <NavigationContext.Provider
      value={{push, prefetch, goToPersonList, goToNetworkItem, pushUnsafe}}
    >
      {children}
    </NavigationContext.Provider>
  );
}

export function useNavigation(): ContextValue {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }

  return context;
}
