import {Chip as MantineChip} from '@mantine/core';
import classes from '@/ui/theme/themed-components/chip.module.css';

const DEFAULT_SIZE = 'sm';
const DEFAULT_VARIANT = 'filled';
const DEFAULT_COLOR = 'primary';

export const Chip = MantineChip.extend({
  defaultProps: {
    size: DEFAULT_SIZE,
    variant: DEFAULT_VARIANT,
    color: DEFAULT_COLOR,
  },
  classNames: {
    root: classes.root,
  },
});
