import {IconProps} from '@/ui/icon/types';
import * as React from 'react';
import type {SVGProps} from 'react';
function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1600 1600" {...props}>
      <path
        fill="#000"
        d="M562.72 322.719a64.02 64.02 0 0 0-18.771 45.282 64.015 64.015 0 0 0 18.771 45.281l350.08 350.08a52.815 52.815 0 0 1 14.781 36.64 52.812 52.812 0 0 1-14.781 36.64L562.72 1186.72a64.046 64.046 0 0 0-16.573 61.86 64.03 64.03 0 0 0 45.281 45.28 64.037 64.037 0 0 0 61.855-16.58l350.077-350.075A179.99 179.99 0 0 0 1056 800.007c0-47.697-18.93-93.448-52.64-127.197L653.283 322.73a64.023 64.023 0 0 0-45.282-18.771 64.02 64.02 0 0 0-45.281 18.771v-.011Z"
      />
    </svg>
  );
}
export function SvgChevronRight(props: IconProps) {
  const {size, className, ...remainingProps} = props;
  return (
    <Icon
      className={`svg-icon ${className || ''}`}
      width={size}
      height={size}
      fill="currentColor"
      data-icon="chevron-right"
      {...remainingProps}
    />
  );
}
