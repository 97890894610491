'use client';

import {createContext, useState, ReactNode} from 'react';
import {AppShellProps} from '@mantine/core';

interface AppShellProviderProps {
  aside?: AppShellProps['aside'];
  disabled?: AppShellProps['disabled'];
  footer?: AppShellProps['footer'];
  navbar?: AppShellProps['navbar'];
  header?: AppShellProps['header'];
  children?: ReactNode;
}

interface AppShellContext {
  state: Omit<AppShellProviderProps, 'children'>;
  setState: (newState: AppShellProviderProps) => void;
}

const defaultProps: AppShellProviderProps = {
  aside: undefined,
  disabled: undefined,
  footer: undefined,
  navbar: {width: 300, breakpoint: 'sm', collapsed: {desktop: false, mobile: true}},
  header: undefined,
};

const defaultContext: AppShellContext = {
  state: defaultProps,
  setState: () => {},
};

export const AppShellContext = createContext(defaultContext);

export function AppShellProvider(props: AppShellProviderProps) {
  const {children, ...others} = props;
  const [appShellProps, setAppShellProps]: [AppShellProviderProps, AppShellContext['setState']] =
    useState({
      ...defaultProps,
      ...others,
    });

  function setState(newState: AppShellProviderProps) {
    setAppShellProps(Object.assign({}, appShellProps, newState));
  }

  return (
    <AppShellContext.Provider
      value={{
        state: appShellProps,
        setState,
      }}
    >
      {children}
    </AppShellContext.Provider>
  );
}
