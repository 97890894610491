'use client';
import React, {PropsWithChildren} from 'react';
import {MantineProvider, Popover, createTheme, DEFAULT_THEME, rem} from '@mantine/core';
import {Button} from '@/ui/theme/themed-components/button';
import {ActionIcon} from '@/ui/theme/themed-components/action-icon';
import {Avatar} from '@/ui/theme/themed-components/avatar';
import {Chip} from '@/ui/theme/themed-components/chip';
import {Input} from '@/ui/theme/themed-components/input';
import {MenuItem} from '@/ui/theme/themed-components/menu-item';
import {Tooltip} from '@/ui/theme/themed-components/tooltip';
import {Paper} from '@/ui/theme/themed-components/paper';
import {LoadingOverlay} from '@/ui/theme/themed-components/loading-overlay';
import {AppShell} from '@/ui/theme/themed-components/app-shell';
import {Pill} from '@/ui/theme/themed-components/pill';
import {Divider} from '@/ui/theme/themed-components/divider';
import {Breadcrumbs} from '@/ui/theme/themed-components/breadcrumbs';
import {ScrollArea, ScrollAreaAutosize} from '@/ui/theme/themed-components/scroll-area';
import {Skeleton} from '@/ui/theme/themed-components/skeleton';
import {gray, primary, dark, danger, success} from '@/ui/theme/colors';
import {fontSizes, heights, iconSizes} from '@/ui/theme/sizes';
import {cssVariablesResolver} from '@/ui/theme/css-variables-resolver';
import {Checkbox} from '@/ui/theme/themed-components/checkbox';

const theme = createTheme({
  scale: 1,
  cursorType: 'pointer',
  primaryColor: 'primary',
  primaryShade: {
    light: 5,
    dark: 5,
  },
  colors: {
    default: gray,
    primary,
    gray,
    dark,
    danger,
    success,
  },
  shadows: {
    xs: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
  },
  // TODO: watch & replace with next/font loading in HC-3149
  fontFamily: 'var(--font-inter)',
  defaultGradient: {
    from: '#9C36B5',
    to: 'primary',
    deg: 45,
  },
  fontSizes: {
    xs: fontSizes.xs,
    sm: fontSizes.sm,
    md: fontSizes.md,
    lg: fontSizes.lg,
    xl: fontSizes.xl,
  },
  spacing: {
    ...DEFAULT_THEME.spacing,
    xxs: rem('6px'),
  },
  defaultRadius: 'md',
  components: {
    ActionIcon,
    Avatar,
    Button,
    Chip,
    Input,
    MenuItem,
    Tooltip,
    Paper,
    AppShell,
    LoadingOverlay,
    Pill,
    Divider,
    Breadcrumbs,
    ScrollArea,
    ScrollAreaAutosize,
    Skeleton,
    Checkbox,
    Popover: Popover.extend({
      defaultProps: {
        shadow: 'md',
      },
    }),
  },
  other: {
    heights,
    iconSizes,
  },
});

export interface ThemeProps {}

export function Theme({children}: PropsWithChildren<ThemeProps>) {
  return (
    <MantineProvider
      theme={theme}
      defaultColorScheme="light"
      cssVariablesResolver={cssVariablesResolver}
    >
      {children}
    </MantineProvider>
  );
}
