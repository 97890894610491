'use client';

import {PropsWithChildren} from 'react';
import {useRouter} from 'next/navigation';
import {Notifications} from '@mantine/notifications';
import {RecordMap} from 'relay-runtime/lib/store/RelayStoreTypes';

import {Theme} from '@/ui/theme/theme';
import {AppShellProvider} from '@/providers/app-shell-provider';
import {AtlasPrefabProvider} from '@/providers/atlas-prefab-provider';
import {NavigationProvider} from '@/hooks/use-navigation/use-navigation';
import {AtlasRelayEnvProvider} from '@/providers/atlas-relay-env-provider';
import {initDatadogRum, DatadogRumProvider} from '@/providers/datadog-rum';
import {CurrentUserProvider} from '@/providers/current-user-provider';

import type {MockEnvironment} from 'relay-test-utils';
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';

initDatadogRum();

export type GlobalContextsProps = {
  children: React.ReactNode;
  initialRecords: RecordMap | undefined;
  sequenceId?: string;
  // To support testing only
  relayEnvironment?: MockEnvironment | RelayModernEnvironment;
} & PropsWithChildren;

export function GlobalContexts({
  initialRecords,
  sequenceId,
  relayEnvironment,
  children,
}: GlobalContextsProps) {
  const router = useRouter();

  // Ensure Relay can be used in CurrentUserProvider, which is used
  // for contextAttributes for PrefabProvider in GlobalContextsInner
  return (
    <NavigationProvider router={router}>
      <AtlasRelayEnvProvider
        initialRecords={initialRecords}
        sequenceId={sequenceId}
        relayEnvironment={relayEnvironment}
      >
        <DatadogRumProvider>
          <CurrentUserProvider>
            <AtlasPrefabProvider>
              <Theme>
                <AppShellProvider>
                  <Notifications autoClose={4000} />
                  {children}
                </AppShellProvider>
              </Theme>
            </AtlasPrefabProvider>
          </CurrentUserProvider>
        </DatadogRumProvider>
      </AtlasRelayEnvProvider>
    </NavigationProvider>
  );
}
