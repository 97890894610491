import {ActionIcon as MantineActionIcon, rem} from '@mantine/core';
import {heights} from '@/ui/theme/sizes';
const DEFAULT_SIZE = 'md';
const DEFAULT_VARIANT = 'default';
const DEFAULT_COLOR = 'default';

interface VarsObject {
  root: {
    '--ai-size'?: string;
    '--button-icon-color'?: string;
    '--ai-bd'?: string;
  };
}

export const ActionIcon = MantineActionIcon.extend({
  defaultProps: {
    size: DEFAULT_SIZE,
    variant: DEFAULT_VARIANT,
    color: DEFAULT_COLOR,
  },
  vars: (theme, props) => {
    const vars: VarsObject = {
      root: {
        '--ai-size':
          typeof props.size === 'string' && heights[props.size]
            ? heights[props.size]
            : heights[DEFAULT_SIZE],
        '--button-icon-color': 'var(--ai-color)',
      },
    };
    if (['default', 'subtle'].includes(props.variant || '') && props.color === 'default') {
      vars.root['--button-icon-color'] = 'var(--mantine-color-gray-6)';
    }
    if (props.variant === 'gradient') {
      vars.root['--ai-bd'] = `${rem(1)} solid ${theme.colors.primary[7]}`;
    }
    if (props.disabled) {
      vars.root['--button-icon-color'] = 'var(--_disabled-color)';
      vars.root['--ai-bd'] = `${rem(1)} solid transparent`;
    }
    return vars;
  },
});
