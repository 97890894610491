'use client';

import {PropsWithChildren} from 'react';
import {PrefabProvider} from '@prefab-cloud/prefab-cloud-react';

import {LogLevel} from '@/logger/types';
import {datadogTransport} from '@/logger/transports/datadog';
import {CLIENT_PREFAB_API_KEY, ApplicationConfiguration} from '@/config';
import {useCurrentUserMaybe} from './current-user-provider';
import {useRootNetworkMaybe} from '@/hooks/use-root-network/use-root-network';

const MINUTE = 60000;

type AtlasPrefabProviderProps = {
  pollInterval?: number;
  networkSlug?: string;
  currentUser?: {
    id: string;
    primaryEmail?: string | null;
    fullName?: string | null;
    impersonatedBy?: {
      id: string;
    } | null;
  } | null;
};

export function AtlasPrefabProvider({children}: PropsWithChildren) {
  const rootNetwork = useRootNetworkMaybe();
  const maybeCurrentUser = useCurrentUserMaybe();
  const currentUser = maybeCurrentUser?.currentUser;

  return (
    <AtlasPrefabProviderBase currentUser={currentUser} networkSlug={rootNetwork?.slug}>
      {children}
    </AtlasPrefabProviderBase>
  );
}

export function AtlasPrefabProviderBase({
  children,
  pollInterval,
  currentUser,
  networkSlug,
}: PropsWithChildren & AtlasPrefabProviderProps) {
  const user = currentUser
    ? {
        key: currentUser.id,
        email: currentUser.primaryEmail || '',
        name: currentUser.fullName || '',
        impersonatedById: currentUser?.impersonatedBy?.id,
      }
    : {};

  const network = networkSlug
    ? {
        key: networkSlug,
        slug: networkSlug,
      }
    : {};

  const prefabPollInterval = pollInterval ?? MINUTE;

  return (
    <PrefabProvider
      pollInterval={prefabPollInterval}
      apiKey={CLIENT_PREFAB_API_KEY}
      // @ts-expect-error: fields of ContextAttributes cannot be nullable ENG-1224
      contextAttributes={{user, network}}
      collectEvaluationSummaries={ApplicationConfiguration.collectPrefabEvalutions}
      onError={(error: Error) => {
        const message = "Error encountered in Prefab's React SDK";

        // We should never use a transport directly like this. However, this is
        // an exceptional use case where prefab has thrown an error and we cannot
        // rely on our logger implementation to capture the issue. In these cases,
        // we always send to datadog directly.
        datadogTransport().error(
          {
            message, // Not used directly by transport but required by API
            formattedMessage: message,
            timestamp: new Date().toISOString(), // Not used directly by transport but required by API
            logLevel: LogLevel.Error, // Not used directly by transport but required by API
            loggerName: 'NOT USED DIRECTLY BY TRANSPORT', // Not used directly by transport but required by API
            data: {
              errorMessage: error?.message || error?.toString(),
            },
          },
          error,
        );
      }}
    >
      {children}
    </PrefabProvider>
  );
}
